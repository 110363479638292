import { Cake, Casino, Dining, Favorite, Flatware, LocalDrink, Luggage, MonochromePhotos, Nightlife, WavingHand } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Grid, Grid2, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";

import "./core/stylesheet.css";
import { useNavigate } from "react-router-dom";
import { AboutOus } from "./sites/AboutOus";
import { Programm } from "./sites/Programm";


export const Main:React.FC = () => {
    const navigation = useNavigate();

    return(
        <>
        {/*
        <ParallaxBanner style={{ aspectRatio: '2 / 1' }}>

        <ParallaxBannerLayer image="/bg_main.jpg" speed={-20} />
        <ParallaxBannerLayer>
            <Typography variant='h1' sx={{textAlign: "center", color: "white"}}>Wir heiraten!</Typography>
        </ParallaxBannerLayer>

        </ParallaxBanner>
         */}

                <Box sx={{backgroundColor: "#d1bc8a", width: "100%", color: "#fff"}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '45vh', border: "3px solid", borderColor: "#fff"}}
                    >
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <Typography align="center" sx={{fontSize: 70, fontFamily: "Playfair Display"}}>
                                P I A
                            </Typography>
                            <Typography align="center" sx={{ml: -1, pt: 0, mt: -10,fontSize: 150, fontFamily: 'Herr Von Muellerhoff', fontStyle: 'italic'}}>
                                <img src="/and.png" width={200}/>
                            </Typography>
                            <Typography align="center" sx={{pt: 0, mt: -10,fontSize: 70, fontFamily: "Playfair Display"}}>
                                D A N I E L 
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

            {/* Durch den Menüoffset eine Sektion versetzt */}
            <Box id="login-info">
                <Box sx={{backgroundColor: "#fff", width: "100%", color: "#8e7b50"}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '30vh'}}
                    >
                        <Typography align="center" sx={{fontSize: 40, fontFamily: "Playfair Display"}}>
                            HERZLICHE EINLADUNG
                        </Typography>
                        <Typography align="center" sx={{mt: 3, fontSize: 20, fontFamily: "Playfair Display"}}>
                            ZU UNSERER HOCHZEITSFEIER AM 14. JUNI 2025 IM GUT SARNOW
                        </Typography>
                    </Grid>

                </Box>
            </Box>


            {/* Durch den Menüoffset eine Sektion versetzt */}
            <Box >
                <Box sx={{backgroundColor: "#d1bc8a", width: "100%", color: "#fff", pb: 5}}>´
                    <Box sx={{p:3, ml: {xs: 1, md: 15}, mr: {xs: 1, md: 15} }}>
                        <Typography variant="h4" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>ANMELDUNG</Typography>  

                        <Typography sx={{float: "center", textAlign: "center", mb: 3}} >
                            Über den folgenden Button gelangt Ihr zum Gästeportal. 
                            <br/>
                            <br/>Dort bekommt Ihr die Möglichkeit, für die Hochzeitsfeier am Freitag sowie Samstag zu zusagen. Falls gewünscht, könnt ihr anschließend über einen Link Hotelzimmer für diesen beiden Tage buchen. 
                            <br/>Des weiteren freuen wir darüber, wenn Ihr eure Musikwünsche hinterlasst und Bilder der Hochzeitsfeier zur Verfügung stellt. 
                        </Typography>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '12'}}
                        >
                            <Alert sx={{width: 200, mb: 2}} severity="info">
                                Das Gästeportal wird am 01.11.2024 freigeschaltet.
                            </Alert>  
                            <Button disabled onClick={() => navigation("/guest")}  variant="contained">Zum Gästeportal</Button>
                        </Grid>
                        <Box sx={{alignContent: "center", alignItems: "center", float: "center", justifyItems: "center"}}>
                            
                        </Box>
                        
                    </Box>

                </Box>
            </Box>


            <Box id="aboutous"/>
            <AboutOus/>
            
            <Box id="programm"/>
            <Programm/>


            <Box id="location"/>
            <Box sx={{mt: 10, m: 3, ml: {xs: 1, md: 15}, mr: {xs: 1, md: 15}}}>
                <Typography variant="h4" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>LAGEPLAN</Typography>
                <Box sx={{ml: 5, mr: 5}}>
                    <iframe width="100%" height="400px" frameBorder="0" allowFullScreen allow="geolocation" src="https://umap.openstreetmap.de/de/map/unbenannte-karte_68439"></iframe>
                </Box>
                
            </Box>

            <Box id="faq"/>
            <Box sx={{mt: 10, mb: -5}}>
                <Box sx={{backgroundColor: "#d1bc8a", width: "100%", color: "#fff", pt: 3, pb: 15}}>
                    <Box sx={{pl: {xs: 1, md: 15, lg: 40}, pr: {xs: 1, md: 15, lg: 40}}}>
                        <Typography variant="h4" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>FRAGEN UND ANTWORTEN</Typography>
                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Gibt es eine Kleiderordnung?</AccordionSummary>
                            <AccordionDetails>
                                Grundsätzlich freuen wir uns über festliche Kleidung. Es wird jedoch keinen Farbcode geben. 
                                <br/>Bitte beachtet, dass ein Teil der Festlichkeit auf einer Wiese stattfinden wird und daher hohe Schuhe mit dünnem Absatz ungeeignet sind. 
                            </AccordionDetails>
                        </Accordion>


                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Wie komme ich am besten zum Gut Sarnow?</AccordionSummary>
                            <AccordionDetails>
                               Zum Gut Sarnow gibt es eine Bahn- und Busverbindung. Jedoch empfehlen wir, mit dem Auto anzureisen, insbesondere wenn für euch eine Übernachtung im Hotel Gut Sarnow nicht in Frage kommt. Bei Bedarf können Fahrgemeinschaften gebildet werden. Meldet Euch dazu gerne bei uns oder unseren Trauzeuginnen und Trauzeugen.  
                            </AccordionDetails>
                        </Accordion>
                        

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Finde ich Parkplätze im Gut Sarnow?</AccordionSummary>
                            <AccordionDetails>
                                Parkplätze sind im Gut Sarnow ausreichend vorhanden. Nutzt am besten den Parkplatz vor dem Restaurant.
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Gibt es eine Schlecht-Wetter-Alternative?</AccordionSummary>
                            <AccordionDetails>
                                Ja, für eine Schlecht-Wetter-Alternative ist gesorgt. Im Falle von Regen wird unsere freie Trauung in der Scheune des Guts stattfinden. 
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Findet die standesamtliche Trauung an diesem Tag statt?</AccordionSummary>
                            <AccordionDetails>
                                Nein, wir werden bereits einige Tage früher standesamtlich heiraten und unsere Ehe an diesem Tag vor Euch durch eine freie Trauung bekunden.  
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Ist die Hochzeitsfeier Open-End?</AccordionSummary>
                            <AccordionDetails>  
                            Auch die schönsten Dinge haben ein Ende. So wird auch unsere Hochzeitsfeier offiziell gegen 3 Uhr enden. Musikalisch werden wir von unserem DJ bis 2 Uhr begleitet werden. Wenn es die Wetterbedingungen zulassen, können wir den Tag nach 3 Uhr an der Feuerschale ausklingen lassen.  
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Kann ich meinen Hund mitbringen?</AccordionSummary>
                            <AccordionDetails>
                                Grundsätzlich ist es möglich, Hunde im Gut Sarnow mitzubringen. Da jedoch auch Pferde auf dem Gut leben, würden wir euch bitten, dies mit dem Hotel abzusprechen. 
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Wie sieht es mit Kindern auf der Hochzeit aus?</AccordionSummary>
                            <AccordionDetails>
                                Wir freuen uns über jeden Gast auf unserer Hochzeitsfeier. Deshalb sind auch Kinder herzlich willkommen!
                                < p ></p>Damit sich unsere kleinen Gäste nicht langweilen, haben wir von 16 bis 17 Uhr Pony-Reiten auf dem Pferdehof im Gut Sarnow organisiert. Das Ponyreiten ist ab 2 Jahren möglich, wenn Eltern das Kind festhalten. Die Kinder sollten dabei möglichst wenig Haarschmuck tragen, damit eine Reitkappe getragen werden kann. Wir bitten euch, Euer Kind für das Ponyreiten über das Gästeportal anzumelden, damit wir besser planen können!
                                <br/> 
                                <br/> Solltet Ihr einen Hochstuhl für Euer Kind brauchen, gebt uns bitte persönlich oder über das Gästeportal Bescheid!
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Wann ist Check-In und Check-Out im Hotel Gut Sarnow?</AccordionSummary>
                            <AccordionDetails>
                               Der Check-In ist jeweils am Freitag und Samstag ab 15 Uhr, der Check-Out bis 10 Uhr am Sonntag möglich.
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Wie verhält es sich mit den Preisen im Hotel und Restaurant Gut Sarnow?</AccordionSummary>
                            <AccordionDetails>
                               Das Einzelzimmer (in der Classic-Kategorie) im Hotel Gut Sarnow kostet 119 Euro pro Nacht, das Doppelzimmer 145 Euro. Die Komfort-Kategorie umfasst Einzelzimmer im Preis von 129 Euro und Doppelzimmer im Preis von 159 Euro. Die Suite liegt bei 189 Euro pro Nacht. Die Preise verstehen sich inkl. Frühstück. Insgesamt verfügt das Hotel über 38 Zimmer und zwei Ferienwohnungen. Bitte gebt am Ende der Buchung des Hotelzimmers an, welche Zimmerkategorie Ihr wünscht.
                               <p />
                               Die Preise im Restaurant beginnen bei 17 Euro für eine Hauptspeise und jeweils 7 Euro für eine Vorspeise sowie Dessert. 
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Gibt es einen Fotografen auf der Hochzeit?</AccordionSummary>
                            <AccordionDetails>
                               Für unsere Hochzeitsfeier haben wir eine professionelle Fotografin engagiert, die uns den gesamten Tag begleiten wird. Diese Fotos werden wir nach der Hochzeit kennwortgeschützt über diese Internetseite hochladen. Wir freuen uns dennoch über Eure Schnappschüsse, die Ihr im Nachhinein über das Gästeportal hochladen könnt! 
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Wie ist die Verpflegung auf der Hochzeit geregelt?</AccordionSummary>
                            <AccordionDetails>
                                Im Rahmen unserer Hochzeitsfeier am Samstag werden selbstverständlich alle Kosten für Getränke und Speisen sowie Unterhaltung von uns übernommen. Also lasst es Euch schmecken und trinkt nach Herzenslust. Wir freuen uns, wenn Ihr ordentlich zulangt! <br/> <br/> Falls Ihr spezielle Ernährungsgewohnheiten oder Unverträglichkeiten habt, gebt diese bitte bei Eurer Anmeldung im Gästeportal an!
                            </AccordionDetails>
                        </Accordion>
                        

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Was können wir dem Brautpaar schenken?</AccordionSummary>
                            <AccordionDetails>
                                Euer Kommen ist das größte Geschenk an uns. Wenn Ihr uns trotzdem etwas schenken möchtet, freuen wir uns über einen Zuschuss für unsere Reisekasse oder alles, was von Herzen kommt!
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary sx={{fontWeight: "bold"}}>Was gibt es noch zu wissen?</AccordionSummary>
                            <AccordionDetails>
                                1. Es wird auf unserer Hochzeit keine Hochzeitstorte geben. <br/>
                                2. Wir heiraten ein Jahr und einen Tag nach unserer Verlobung. <br/>
                                3. Wir wünschen uns ein Fest mit ausgelassener Stimmung und vielen Emotionen! <br/>
                                4. Wir werden vorraussichtlich ein Jahr später kirchlich (katholisch) im engsten Kreis in Böblingen heiraten. <br/>
                                5. Zu unseren liebsten Menschen zählen ungefähr 80 Personen, die wir alle zu unserer Hochzeit eingeladen haben.
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            </Box>

            <Box sx={{mt: 9}}>
                <Box sx={{width: "100%"}}>
                    <Box sx={{pl: {xs: 1, md: 15}, pr: {xs: 1, md: 15}}}>
                        <Typography variant="h6" sx={{mb: 2, flexGrow: 1, textAlign: "center"}}>WIR FREUEN UNS AUF EUCH! <Favorite sx={{mb: -0.5}}/></Typography>

                    </Box>
                </Box>
            </Box>



            <Box key="location" sx={{backgroundColor: "#d1bc8a", pb: 5}}>
                <Typography sx={{fontSize: 11, color: "#fff", m: 3, mb: 0, textAlign: "right"}}>
                    <br/>
                    <br/>
                    <br/>
                    
                
                    Wenn Sie diese Webseite nutzen werden Logfiles inklusiver Ihrer IP-Adresse erfasst. Diese sind technisch notwendig und stellen nach Art. 6 Abs. 1 lit. f DSGVO ein berechtigtes Interesse dar.
                    Daten zur analysezwecken oder der Weitergabe an Dritte werden <u>nicht erfasst</u>. 
                    
                    Diese Seite wird rein privat genutzt und dient der Bereitstellung von Informationen bzgl. der im Text ausgeführten Hochzeit.
                    Es liegt keine Impressumspflicht im Sinne des §5 DDG vor. 
                    
                    Für Fragen stehen wir Ihnen unter der folgenden <a href="mailto:pia-und-daniel@kmiotek.de">E-Mail-Adresse</a> jederzeit gerne zur verfügung!
                    <br/>
                    
                </Typography>
            </Box>
           
        </>
    )
}