import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";

interface IProps {
    msg: string | null;
}

export const Login:React.FC<IProps> = (props) => {
    const [msg,setMsg] = useState(props.msg);
    //
    const [currentPage,setCurrentPage] = useState(0);
    //
    const [email, setEmail] = useState<string>("");

    const handleSubmitLogin = (e: React.SyntheticEvent) => {
        e.preventDefault();
    }

    if (currentPage === 0) {
        return(
            <>
                 <form onSubmit={handleSubmitLogin} autoComplete="on">
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '100vh'}}
                    >
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <TextField
                                label="E-Mail"
                                value={email}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(String(event.target.value))}
                                required
                            />
                        </Grid>
                        <Box sx={{mt: 3}}/>
                        <Grid item xs={3} style={{minWidth: 300}}>
                            <div style={{float: 'right'}}>
                                <Button size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
    else {
        return(
            <>
                
                Fehler!
            </>
        )
    }
} 