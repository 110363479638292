import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { useNavigate } from 'react-router-dom';

const pages = ['Über uns', 'Ablauf', 'Anfahrt'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


interface IProps {
    isLogged: boolean;
    children: React.ReactElement | React.ReactElement[];
}



export const Nvarbar2:React.FC<IProps> = ({isLogged,children}) => {
  const navigation = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateToContact = (pageTo:string) => {
    navigation("/#" + pageTo );
    setTimeout(() => {
    const contactSection = document.getElementById(pageTo);
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // Delay for smoother scroll
  
  }
  return (
    <Box>
    <AppBar position="static" sx={{backgroundColor: "#fff", color: "#8e7b50", position: "fixed", zIndex: 5}}>

      <Container maxWidth="xl" >
          
        <Box 
          sx={{
            display: "flex", 
            justifyContent: "center", // Horizontale Zentrierung
            alignItems: "center",      // Vertikale Zentrierung
            textAlign: "center", 
          }}
        >
          <Typography 
            align="center" 
            sx={{ fontFamily: "Playfair Display", fontSize:  50 }}
          >
            P
          </Typography>
          <Typography 
            align="center" 
            sx={{ ml: 1, mr: 1, fontFamily: "Playfair Display", fontSize: 70 }}
          >
            |
          </Typography>
          <Typography 
            align="center" 
            sx={{ fontFamily: "Playfair Display", fontSize: 50 }}
          >
            D
          </Typography>
        </Box>




        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1,
            display: { xs: 'flex', md: 'flex' },             
            justifyContent: "center", // Horizontale Zentrierung
            alignItems: "center",      // Vertikale Zentrierung
            textAlign: "center", }}
          >
            <Button key="btn-login-info" sx={{ my: 2, color: '#8e7b50', display: 'block' }} onClick={() => navigateToContact("login-info")}>Anmeldung</Button>
            <Button key="btn-aboutous" sx={{ my: 2, color: '#8e7b50', display: 'block' }} onClick={() => navigateToContact("aboutous")}>Über uns </Button>
            <Button key="btn-programm" sx={{ my: 2, color: '#8e7b50', display: 'block' }} onClick={() => navigateToContact("programm")}>Ablauf </Button>
            <Button key="btn-location" sx={{ my: 2, color: '#8e7b50', display: 'block' }} onClick={() => navigateToContact("location")}>Lageplan </Button>
            <Button key="btn-faq" sx={{ my: 2, color: '#8e7b50', display: 'block' }} onClick={() => navigateToContact("faq")}>Fragen </Button>
          </Box>

          {(isLogged) && 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          }




        </Toolbar>       
      </Container>
    </AppBar>
    


    <Box sx={{pt: 20}}>
        {children}
         
    </Box>
    </Box>

  );
}