import React, { useEffect, useState } from "react";
import { Login } from "./Login";

interface IProps {
    children: React.ReactElement;

    isLoading:boolean;
    isLogged: boolean;
    msg: string | null;
    setIsLoading: Function;
    setIsLogged: Function;
    setMsg: Function;
}




export const SecurityDriver:React.FC<IProps> = ({
    children,
    isLoading, isLogged, msg,
    setIsLoading, setIsLogged, setMsg
}) => {



    if (isLoading) {return  <>Bitte warten...</> }
    else if (isLogged) { return <>{children}</> }
    else {
        return <Login msg={msg}/>
    }
}